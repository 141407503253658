import AEM from 'base/js/aem';
import { Collapse } from 'bootstrap';
class Header extends AEM.Component {
    init() {
        this.NS = 'cmp';
        this.IS = 'header';
        this.active = 0;

        this.disableScroll();
        this.cacheElements(this.element);


        // prevents multiple initialization
        this.element.removeAttribute(`data-${ this.NS }-is`);
        this.initPanelSwitch();
        this.tagHeaderLinks(this.element);

        // Handle Signout
        this.bindSignout();

        // addEventListeners to focus and select the coveo header search
        const searchDropdown = this.element.querySelector('.dropdown-toggle#searchDropdown');
        if (searchDropdown) {
            searchDropdown.addEventListener('shown.bs.dropdown', e => {
                e.target.closest('.search-dropdown').querySelector('atomic-search-box').shadowRoot.querySelector('input').focus();
                e.target.closest('.search-dropdown').querySelector('atomic-search-box').shadowRoot.querySelector('input').select();
            });
        }
    }

    disableScroll(){
        this.element.querySelector('.navbar-nav-container.navbar-collapse').addEventListener('show.bs.collapse', e => {
            if (e.target.classList.contains('navbar-nav-container')) {
                document.body.classList.add('noscroll');
            }
        });

        this.element.querySelector('.navbar-nav-container.navbar-collapse').addEventListener('hide.bs.collapse', e => {
            if (e.target.classList.contains('navbar-nav-container')) {
                document.body.classList.remove('noscroll');
            }
        });

        const navbarContent = this.element.querySelector('.navbar-nav-container.navbar-collapse');

        const collapseNavBar = () => {
            const myCollapse = Collapse.getInstance(navbarContent);
            if (myCollapse) {
                myCollapse.hide();
            }
        };

        const handleClosure = event => !navbarContent.contains(event.target) && collapseNavBar();
        window.addEventListener('click', handleClosure);
    }

    /**
	 * Caches the elements as defined via the {@code data-header-hook="ELEMENT_NAME"} markup API
	 *
	 * @private
	 * @param {HTMLElement} wrapper The Tabs wrapper element
	 */
    cacheElements(wrapper) {
        this.cacheEl = {};
        this.cacheEl.self = wrapper;
        let hooks = this.cacheEl.self.querySelectorAll(`[data-${ this.NS }-hook-${ this.IS }]`);

        for (let i = 0; i < hooks.length; i++) {
            let hook = hooks[i];
            if (hook.closest(`.${ this.NS }-${ this.IS}`) === this.cacheEl.self) { // only process own tab elements
                let capitalized = this.IS;
                capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
                let key = hook.dataset[`${this.NS }Hook${ capitalized}`];
                if (this.cacheEl[key]) {
                    if (!Array.isArray(this.cacheEl[key])) {
                        let tmp = this.cacheEl[key];
                        this.cacheEl[key] = [ tmp ];
                    }
                    this.cacheEl[key].push(hook);
                } else {
                    this.cacheEl[key] = hook;
                }
            }
        }
    }

    initPanelSwitch() {
        if (window.Granite && window.Granite.author && window.Granite.author.MessageChannel) {
            /*
			 * Editor message handling:
			 * - subscribe to "cmp.panelcontainer" message requests sent by the editor frame
			 * - check that the message data panel container type is correct and that the id (path) matches this
			 *  - specific Tabs component if so, route the "navigate" operation to enact a navigation of the Tabs based
			 *  - on index data.
			 */
            window.CQ = window.CQ || {};
            window.CQ.CoreComponents = window.CQ.CoreComponents || {};
            window.CQ.CoreComponents.MESSAGE_CHANNEL = window.CQ.CoreComponents.MESSAGE_CHANNEL ||
                new window.Granite.author.MessageChannel('cqauthor', window);
            window.CQ.CoreComponents.MESSAGE_CHANNEL.subscribeRequestMessage('cmp.panelcontainer', message => {
                if (message.data && message.data.type === 'cmp-header' &&
                    message.data.id === this.cacheEl.self.dataset.cmpPanelcontainerId) {
                    if (message.data.operation && message.data.operation === 'navigate') {
                        this.active = message.data.index;
                        this.refreshActive();
                    }
                }
            });
        }
    }

    /**
	 * Refreshes the tab markup based on the current index
	 */
    refreshActive() {
        let tabpanels = this.cacheEl.tabpanel;
        let tabs = this.cacheEl.tab;

        if (tabpanels) {
            if (Array.isArray(tabpanels)) {
                for (let i = 0; i < tabpanels.length; i++) {
                    if (i === parseInt(this.active, 10)) {
                        tabpanels[i].classList.add('show');
                        tabpanels[i].removeAttribute('aria-hidden');
                        tabpanels[i].setAttribute('data-bs-popper', 'none');
                        tabs[i].classList.add('show');
                        tabs[i].setAttribute('aria-selected', true);
                    } else {
                        tabpanels[i].classList.remove('show');
                        tabpanels[i].setAttribute('aria-hidden', true);
                        tabpanels[i].removeAttribute('data-bs-popper');
                        tabs[i].classList.remove('show');
                        tabs[i].setAttribute('aria-selected', false);
                    }
                }
            } else {
                // only one tab
                tabpanels.classList.add('show');
                tabs.classList.add('show');
            }
        }
    }

    /**
	 * Adds tracking information to header links on page load.
	 */
    tagHeaderLinks(header) {
        const megaMenuItems = header.querySelectorAll('.nav-mega>li');
        megaMenuItems.forEach(menuItem => {
            const megaMenuPanel = menuItem.querySelector('.mega-menu');
            if (megaMenuPanel) {
                const topLevelLabel = megaMenuPanel.dataset.trackingInformation;
                const menuLinks = megaMenuPanel.querySelectorAll('.header-menu-links');
                menuLinks.forEach(menuLink => {
                    const menuLinkLabel = menuLink.textContent.trim();
                    const secondLevelTracking = `${topLevelLabel}|${menuLinkLabel}`;
                    menuLink.dataset.trackingInformation = secondLevelTracking;
                    const secondLevelTarget = menuLink.getAttribute('aria-controls');
                    const secondLevelPanel = document.getElementById(secondLevelTarget);
                    if (secondLevelPanel) {
                        secondLevelPanel.querySelectorAll('.cmp-text').forEach(textContainer => {
                            let groupTitle = textContainer.querySelectorAll('h6');
                            if (groupTitle.length > 0) {
                                groupTitle.forEach(group => {
                                    if (group.nextElementSibling) {
                                        const groupLinks = group.nextElementSibling.querySelectorAll('a');
                                        this.processGroupLinks(group, secondLevelTracking, groupLinks);
                                    }
                                });
                            } else {
                                groupTitle = textContainer.firstElementChild;
                                if (groupTitle) {
                                    const groupLinks = textContainer.querySelectorAll('a');
                                    this.processGroupLinks(groupTitle, secondLevelTracking, groupLinks);
                                }
                            }
                        });
                    }
                });
            }
        });
    }

    processGroupLinks(group, secondLevelTracking, groupLinks) {
        const groupTitleText = group.textContent ? group.textContent.trim() : 'GROUP TEXT MISSING';
        const thirdLevelTracking = `${secondLevelTracking}|${groupTitleText}`;
        group.dataset.trackingInformation = thirdLevelTracking;
        group.dataset.lpos = 'main-menu';
        groupLinks.forEach(link => {
            const linkText = link.textContent ? link.textContent.trim() : 'LINK TEXT MISSING';
            link.dataset.trackingInformation = `${thirdLevelTracking}|${linkText}`;
            link.dataset.lpos = 'menu-main';
        });
    }

    bindSignout() {
        let signout = this.element.querySelector('[data-function="signout"]');
        if (signout) {
            signout.addEventListener('click', () => {
                fetch(signout.dataset.signoutCheckUrl, {
                    credentials: 'include'
                }).then(response => {
                    if (response.ok) {
                        window.location.replace(signout.dataset.signoutUrl);
                    } else {
                        throw new Error(`${response.status } Failed Fetch `);
                    }
                }).catch(() => {
                    window.location.replace(signout.dataset.signoutRedirectUrl);
                });
            });
        }
    }
}
export { Header };
