import AEM from 'base/js/aem';
import { createApp } from 'vue';
import ShopMenu from './ShopMenu.vue';

class HeaderMenu extends AEM.Component {
    init() {
        this.initShopMenu();
    }

    businessAudienceLabel = '';
    gamingAudienceLabel = '';
    consumerAudienceLabel = '';
    contentCreatorAudienceLabel = '';
    directBuyText = '';
    shopAMDText = '';
    forText = '';
    seeAllText = '';
    visitStoreText = '';

    initShopMenu() {
        const placeHolder = document.getElementById('shop-menu');
        if (!placeHolder || placeHolder.classList.contains('shop-menu-loaded')) {
            return;
        }
        const labelElement = this.element.querySelector('[data-gaming-audience-text]');
        if (labelElement) {
            this.businessAudienceLabel = labelElement.dataset.businessAudienceText;
            this.consumerAudienceLabel = labelElement.dataset.consumerAudienceText;
            this.gamingAudienceLabel = labelElement.dataset.gamingAudienceText;
            this.contentCreatorAudienceLabel = labelElement.dataset.contentCreatorAudienceText;
            this.shopAMDText = labelElement.dataset.shopAmdText;
            this.directBuyText = labelElement.dataset.directBuyText;
            this.forText = labelElement.dataset.forText;
            this.seeAllText = labelElement.dataset.seeAllText;
            this.visitStoreText = labelElement.dataset.visitStoreText;
        }
        const shopMenuComponent = createApp(ShopMenu);
        shopMenuComponent.provide('businessAudienceLabel', this.businessAudienceLabel);
        shopMenuComponent.provide('directBuyText', this.directBuyText);
        shopMenuComponent.provide('consumerAudienceLabel', this.consumerAudienceLabel);
        shopMenuComponent.provide('gamingAudienceLabel', this.gamingAudienceLabel);
        shopMenuComponent.provide('contentCreatorAudienceLabel', this.contentCreatorAudienceLabel);
        shopMenuComponent.provide('shopAMDText', this.shopAMDText);
        shopMenuComponent.provide('forText', this.forText);
        shopMenuComponent.provide('visitStoreText', this.visitStoreText);
        shopMenuComponent.provide('seeAllText', this.seeAllText);
        shopMenuComponent.mount(placeHolder);
    }
}

export { HeaderMenu };
