<template>
	<template v-if="shopMenu">
		<div
				role="tablist"
				class="cmp-headermenu__tablist nav flex-column nav-pills"
				aria-orientation="vertical"
				aria-multiselectable="false">
			<button role="tab"
							type="button"
							id="shop-products-tab"
							class="cmp-headermenu__tab cmp-headermenu__tab--active active nav-link header-menu-links"
							data-bs-toggle="pill"
							data-bs-target="#shop-products-tabpanel"
							aria-controls="$shop-products-tabpanel"
							tabindex="0"
							data-tracking-information="Shop Products"
							data-cmp-hook-headermenu="tab">{{ shopAMDText }}<span class="chevron-right-icon"></span>
			</button>
		</div>
		<div class="cmp-headermenu__tabcontent">
			<div id="shop-products-tabpanel"
					 role="tabpanel"
					 aria-labelledby="shop-products-tab"
					 tabindex="0"
					 class="cmp-headermenu__tabpanel cmp-headermenu__tabpanel--active active"
					 data-cmp-hook-headermenu="tabpanel">
				<div class="cmp-headermenu__accordionheading" role="tab" id="shop-products-heading">
					<a class="collapsed header-menu-links" v-bind:data-tracking-information="`Shop|${shopAMDText}`"
						 data-bs-toggle="collapse"
						 href="#shop-products-collapse" aria-expanded="false" aria-controls="shop-products-collapse"
						 data-cmp-hook-headermenu="accordions">{{ shopAMDText }}<span class="chevron-up-down-icon"></span>
					</a>
				</div>
				<div id="shop-products-collapse" class="collapse cmp-headermenu__accordionbody" role="tabpanel"
						 data-tracking-information="Shop Products" aria-labelledby="shop-products-heading">
					<div class="cmp-container">
						<div class="row">
							<div v-if="directBuy" class="col-xl-3">
								<h6 v-bind:data-tracking-information="`Shop|${shopAMDText}|Direct Buy`">
									{{ directBuyText }}
								</h6>
								<ul>
									<li>
										<a target="_blank" :href="`https://www.amd.com/${ drupalLanguage }/direct-buy`">{{ visitStoreText }}</a>
									</li>
								</ul>
							</div>
							<div v-for="menuitem in shopMenu" :key="menuitem.id" class="col-xl-3">
								<h6>{{ menuitem.text }}</h6>
								<ul>
									<li v-for="item in getMenuitems(menuitem.items)">
										<a v-bind:href="item.link"
											 v-bind:data-tracking-information="`Shop|${shopAMDText}|${menuitem.text}|${item.text}`">
											{{ item.text }}
										</a>
									</li>
									<li v-if="menuitem.link">
										<a v-bind:href="menuitem.link"
											 v-bind:data-tracking-information="`Shop|${shopAMDText}|${menuitem.text}|All`">{{ seeAllText }}
										</a>
									</li>
								</ul>
							</div>
							<div v-if="getCountry() == 'us'" class="col-xl-3">
								<h6 v-bind:data-tracking-information="`Shop|${shopAMDText}|FanStore`">
									AMD Gear
								</h6>
								<ul>
									<li><a href="https://amdfanstore.com/" target="_blank">{{ visitStoreText }}</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>
<script setup>
import {getCookie} from 'base/js/templates/general/consentCheck.js';
import { getCountry} from 'base/js/templates/general/getCountry.js';
import {inject, ref} from 'vue';
import {mapLanguage} from 'base/js/templates/general/mapDrupalLanguage.js';

function lsTest() {
	const test = 'test';
	try {
		sessionStorage.setItem(test, test);
		sessionStorage.removeItem(test);
		return true;
	} catch (e) {
		return false;
	}
}

function translateAudience(audience = '') {
	switch (audience) {
		case 'Business':
			return (businessAudienceLabel);
		case 'Consumer':
			return (consumerAudienceLabel);
		case 'Gaming':
			return (gamingAudienceLabel);
		case 'Content Creator':
			return (contentCreatorAudienceLabel);
		default:
			return audience;
	}
}

function formatDrupalLink(linkFragment) {
	return `https://www.amd.com${linkFragment}`;
}

function getMenuitems(items) {
	const filterItems = items.filter(item => item.hasOwnProperty('text'));
	return filterItems;
}

const shopMenu = ref({});
const region = getCountry();
const storageIsSupported = lsTest();

// String translations.
const businessAudienceLabel = inject('businessAudienceLabel', 'Business');
const gamingAudienceLabel = inject('gamingAudienceLabel', 'Gaming');
const consumerAudienceLabel = inject('consumerAudienceLabel', 'For Home');
const contentCreatorAudienceLabel = inject('contentCreatorAudienceLabel', 'Content Creators');
const directBuyText = inject('directBuyText', 'Buy Direct from AMD');
const shopAMDText = inject('shopAMDText', 'Shop AMD');
const visitStoreText = inject('visitStoreText', 'Visit Store');
const seeAllText = inject('seeAllText', 'View All');

const drupalLanguage = mapLanguage();
const directBuy = ref(false);
const placeholderElement = document.createElement('div');

if (storageIsSupported && sessionStorage.getItem('shopMenu') !== null) {
	if (sessionStorage.getItem('shopMenu') !== 'nonshop') {
		// Storage exists and our menu is there.
		buildMenu(JSON.parse(sessionStorage.getItem('shopMenu')));
	}
} else {
	// Grab feed based on country.
	fetch(`https://www.amd.com/${drupalLanguage}/rest/shop_categories/${region}?_format=json`).then(data => data.json())
			.then(data => {
				if (data.length) {
					let menu = data;
					menu.sort((a, b) => (a.direct_buy < b.direct_buy ? 1: -1));
					const groupedMenu = menu.reduce(function (r, a) {
						r[a.type] = r[a.type] || {};
						r[a.type].items = r[a.type].items || [];
						r[a.type].items.push(a);
						return r;
					}, Object.create(null));
					// Store data into localstorage if supported.
					if (storageIsSupported) {
						sessionStorage.setItem('shopMenu', JSON.stringify(groupedMenu));
					}
					buildMenu(groupedMenu);
				} else {
					if (storageIsSupported) {
						sessionStorage.setItem('shopMenu', 'nonshop');
					}
				}
			});
}

function buildMenu(menuObject) {
	// Sort products with direct buy taking precedence over non-direct buy.
	for (const property in menuObject) {
		if (menuObject[property].items.length == 1) {
			const shopCategory = menuObject[property];
			const categoryItem = shopCategory.items[0];
			placeholderElement.innerHTML = categoryItem.category;
			if (placeholderElement) {
				const placeholderLink = placeholderElement.querySelector('a');
				if (placeholderLink) {
					shopCategory.link = formatDrupalLink(placeholderLink.getAttribute('href'));
					shopCategory.text = placeholderLink.textContent;
				}
				if (categoryItem.direct_buy > 1) {
					directBuy.value = true;
				}
			} else {
				shopCategory.text = shopCategory.type;
			}
		} else {
			const items = menuObject[property].items;
			// Get category links.
			let categoryLinks = items.map(a => a.category);
			// Get unique category links to see if they're all the same.
			categoryLinks = [...new Set(categoryLinks)];
			if (categoryLinks.length == 1) {
				// All the links are the same. We need to add a generic link to the top for all items.
				placeholderElement.innerHTML = items[0].category;
				const placeholderLink = placeholderElement.querySelector('a');
				let categoryName = '';
				let categoryLink = '';
				if (placeholderLink) {
					categoryLink = `${placeholderLink.getAttribute('href')}`;
					categoryName = (items[0].category_list);
				}
				items.forEach(element => {
					element.link = formatDrupalLink(`${categoryLink}?f[0]=audience:${element.audience_value}`);
					element.text = translateAudience(element.audience_value);
					if (element.direct_buy > 1) {
						directBuy.value = true;
					}
				});
				menuObject[property].link = formatDrupalLink(`${categoryLink}`);
				menuObject[property].text = categoryName;
			} else {
				items.forEach(element => {
					placeholderElement.innerHTML = element.category;
					const placeholderLink = placeholderElement.querySelector('a');
					if (placeholderLink) {
						element.text = element.category_list;
						if (placeholderLink.href.includes('Graphics%20Cards')) {
							// Add ALL to graphics card manufacturer filter.
							element.link = formatDrupalLink(`${placeholderLink.getAttribute('href')}?f[0]=manufacturer%3AALL`);
						} else {
							element.link = formatDrupalLink(placeholderLink.getAttribute('href'));
						}
					}
					if (element.direct_buy > 1) {
						directBuy.value = true;
					}
				});
				menuObject[property].link = '';
				menuObject[property].text = items[0].type;
			}
		}
		menuObject[property].id = (Math.random() + 1).toString(36).substring(7);
	}
	shopMenu.value = menuObject;
}

</script>