import READY from 'base/js/ready';
import AEM from 'base/js/aem';

class Forms extends AEM.Component {
	init() {
		READY.ready(this.preventMultiSubmit.bind(this));
	}

	// Disable the submit button to prevent multiple submits
	preventMultiSubmit() {
		if (this.element) {
			this.element.addEventListener('submit', event => {
				let submitButton = event.target.querySelector('input[type="submit"]:not(.mktoButton), button[type="submit"]:not(.mktoButton)');
				if( submitButton) {
					submitButton.disabled=true;
				}
			});
		}
	}
}

export { Forms };
