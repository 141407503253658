import AEM from 'base/js/aem';
import { Modal } from 'bootstrap';

class CustomModal extends AEM.Component {
    init() {
        this.initModal();
    }
    initModal() {
        const modalElem = this.element.querySelector('.modal');
        const modalId = modalElem.getAttribute('id');

        if (modalId){
            const params = new URLSearchParams(window.location.search);
            const modalIdParam = params.has('modal') ? params.get('modal') : '';
            let modalContainer = new Modal(document.getElementById(modalId));

            if (modalIdParam !== '' && modalIdParam === modalId) {
                window.setTimeout(() => modalContainer.show(), 100);
            }
        }
    }
}

export { CustomModal };
