import AEM from 'base/js/aem';
import { richTextYtLinks } from 'base/js/templates/general/youtubeModal.js';

class Teaser extends AEM.Component {
    init() {
        // YouTube modal links for rich text field.
        const links = this.element.querySelectorAll('.card-text a');
        richTextYtLinks(links);
    }
}

export { Teaser };
