import AEM from 'base/js/aem';

class Container extends AEM.Component {
    init() {
        this.initContainer();
    }

    initContainer() {
        const el = this.element;
        const video = el.querySelector('.cmp-container__video')?.querySelector('video');
        const MOBILE = 768;
        const TABLET = 992;
        const isMobile = window.innerWidth < MOBILE;
        const isTablet = window.innerWidth < TABLET;

        if (video) {
            const disableVideo = video.dataset.showVideo === 'disabled';
            if (isMobile) {
                video.poster = video.dataset.mobilePoster;
            } else if (isTablet) {
                video.poster = video.dataset.tabletPoster;
            }
            if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches && !(isMobile && disableVideo)) {
                video.querySelectorAll('source').forEach(src => {
                    if (src.dataset.src && src.dataset.src !== '') {
                        src.src = src.dataset.src;
                    }
                });
                video.load();
                video.play();
                const videoButton = video.nextElementSibling;
                if (videoButton && videoButton.dataset.initialized === undefined) {
                    const buttonClasses = videoButton.classList;
                    videoButton.dataset.initialized = 'initialized';
                    videoButton.addEventListener('click', () => {
                        if (buttonClasses.contains('fa-play')) {
                            video.play();
                            buttonClasses.add('fa-pause');
                            buttonClasses.remove('fa-play');
                        } else {
                            video.pause();
                            buttonClasses.add('fa-play');
                            buttonClasses.remove('fa-pause');
                        }
                    });
                }
            }
        }
    }

    moveImage(video) {
        const image = video.querySelector('picture');
        const parent = video.parentElement;
        if (image) {
            parent.appendChild(image);
            video.remove();
        }
    }
}

export { Container };
