import AEM from 'base/js/aem';
import { initModalMovement } from 'base/js/templates/general/modalMovement.js';

class GalleryModal extends AEM.Component {
    init() {
        const galleryModalElements = document.querySelectorAll('[id^="galleryModal-"]');
        
        // Initialize modal movement for all gallery modal elements
        initModalMovement(galleryModalElements);

        galleryModalElements.forEach(galleryModal => {
            let modalImg = galleryModal.querySelector('.cmp-image__image');
            let modalTitle = galleryModal.querySelector('.cmp-image__title');
            let modalDownload = galleryModal.querySelector('.download');

            galleryModal.addEventListener('show.bs.modal', event => {
                const triggerEl = event.relatedTarget; // Button that triggered the modal
                const src = triggerEl.getAttribute('data-bs-src');
                const lazy = triggerEl.getAttribute('data-bs-lazy');
                const width = triggerEl.getAttribute('data-bs-width');
                const height = triggerEl.getAttribute('data-bs-height');
                const alt = triggerEl.getAttribute('data-bs-alt');
                const title = triggerEl.getAttribute('data-bs-title');

                modalImg.src = src;
                modalDownload.href = src;
                modalImg.loading = lazy;
                modalImg.width = width;
                modalImg.height = height;
                modalImg.alt = alt;
                modalImg.title = title;
                modalTitle.innerHTML = title;
            });
        });
    }
}
export { GalleryModal };
